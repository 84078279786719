@use '../../styles/mixins/media';

.__desktop-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .header-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-base);

    .login-btn {
      background-color: transparent;
    }

    p {
      margin-bottom: 0px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .admin-label {
    margin-left: 30px;
    color: var(--gray-07);
    font-family: var(--mulish);
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 2px;
    padding-bottom: 0px;
  }

  .menu-links {
    display: flex;
    align-items: center;
    gap: var(--spacing-3xl);

    .nav-item {
      color: var(--gray-10);
      font-family: var(--mulish);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
    }
  }

  .new-badge {
    background-color: var(--green-01);
    color: var(--green-06);
    text-transform: uppercase;
    font-family: var(--mulish);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    border-radius: 6px;
    padding: var(--spacing-xs) var(--spacing-sm);
    margin-left: var(--spacing-sm);
  }
}
