@use '../styles/mixins/media';

.__private-layout {
  display: flex;
  flex-direction: row;

  @include media.max-width(md) {
    flex-direction: column;
  }

  .__navigation-header {
    @include media.max-width(md) {
      --nav-header-padding: var(--spacing-lg) var(--spacing-base);
      --nav-header-title-font-size: 22px;
      --nav-header-title-line-height: 32px;
      max-width: 100%;
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    height: 100dvh;
    padding: var(--spacing-lg) var(--spacing-base);
    position: sticky;
    top: 0;
    background: #ffffff;

    .logo {
      svg {
        width: 120px;
        height: 24px;
      }
      margin-bottom: var(--spacing-xl);
    }

    .__create-document-button {
      margin-bottom: var(--spacing-sm);
      width: 100%;
    }

    .app-nav,
    .settings-nav {
      ul {
        all: unset;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: var(--spacing-sm);

        li {
          a {
            display: flex;
            padding: var(--spacing-md) var(--spacing-sm);
            gap: var(--spacing-sm);
            align-items: center;
            color: var(--gray-10);
            font-family: var(--mulish);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration: none;
            padding-top: 6px;
          }

          &.selected {
            border-radius: var(--spacing-sm);
            background: var(--beige-05);
            font-weight: 700;
          }
        }
      }
    }

    .app-nav {
      .avatar {
        width: 20px;
        height: 20px;
        border-radius: 10px;
      }

      .new-badge {
        background-color: var(--green-01);
        color: var(--green-06);
        text-transform: uppercase;
        font-family: var(--mulish);
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        border-radius: 6px;
        padding: var(--spacing-xs) var(--spacing-sm);
      }
    }

    .settings-nav {
      .settings-menu {
        display: flex;
        padding: var(--spacing-md) var(--spacing-sm);
        align-items: center;
        justify-content: space-between;

        color: var(--gray-10);
        font-family: var(--mulish);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        .settings-icon {
          width: 20px;
          height: 20px;
          margin-right: var(--spacing-md);
        }

        .chevron-down-icon {
          width: 16px;
          height: 16px;
          transition: 0.3s;

          &.expanded {
            transform: rotate(-180deg);
          }
        }
      }

      .settings-menu-items {
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;

        &.expanded {
          height: 220px;

          ul {
            gap: 0px;
          }
        }
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: var(--spacing-xl) var(--spacing-xxl);
    max-width: var(--main-layout-max-width);
    margin: 0 auto;

    @include media.max-width(lg) {
      max-width: 100%;
    }

    @include media.between-width(md, lg) {
      padding: 0 var(--spacing-lg);
    }

    @include media.between-width(sm, md) {
      padding: var(--spacing-base) var(--spacing-base) 0 var(--spacing-base);
    }

    @include media.max-width(sm) {
      padding: 0;
    }

    .assessment-header,
    .__profile-page {
      @include media.max-width(sm) {
        padding: 0 var(--spacing-base) var(--spacing-base);
      }
    }

    .title-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--spacing-base);
    }

    .section-main-title {
      &.outside-inherit {
        all: inherit;
        color: #000;
        font-family: var(--fraunces);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 0;
      }

      &.outside {
        all: unset;
        color: #000;
        font-family: var(--fraunces);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 0;
      }
    }
  }

  .floating-button {
    position: fixed;
    bottom: 0px;
    right: 1%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 20px;
    background-color: #000000;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .floating-button:hover {
    background-color: #333333;
    transform: translateX(-50%) translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  .floating-button:active {
    transform: translateX(-50%) translateY(0);
  }

  .floating-button svg {
    width: 20px;
    height: 20px;
  }
}
