.__koi-alert {
  &.alert {
    background-color: #fcf3dc;
    border: 1px solid #dda412;
    border-radius: 8px;
    font-family: var(--mulish);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--gray-10);
    display: flex;
    align-items: center;

    > svg:first-child {
      margin-right: 12px;
      flex-shrink: 0;
      align-self: start;

      path {
        fill: #dda412;
      }
    }
  }
}
