@use '../../styles/mixins/media';

.__trudy-logo {
  height: 24px;
  width: 120px;
  cursor: pointer;

  @include media.max-width(sm) {
    height: 16px;
    width: 80px;
  }
}
