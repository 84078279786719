.__splash-screen {
  .display-block {
    display: block !important;
  }

  .display-none {
    display: none !important;
  }

  .screen {
    font-family: Arial, sans-serif;
    background-color: #f9f8fc;
    color: #333;
    margin: 0;
    height: 100vh;
    position: relative;
    z-index: 99999999;
  }

  .spash-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .flex-direction-row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .not-ready-for-free-trial-container {
    width: 900px;
  }

  .content {
    width: 55%;
  }

  .large-title {
    font-family: var(--fraunces);
    font-size: 32px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
    font-family: var(--mulish);
  }

  /* CTA Button */
  .cta-button {
    background-color: #000;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
    transition: background-color 0.3s ease;
  }

  .cta-button-text {
    font-family: var(--mulish);
    font-size: 16px;
    font-weight: 700;
    color: #fff !important;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .cta-button:hover {
    background-color: #444;
  }

  .features {
    margin-top: 20px;
  }

  .features-title {
    font-size: 16px;
    font-family: var(--fraunces);
    font-weight: 600;
    margin-bottom: 10px;
  }

  .features li {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .features img {
    margin-right: 15px;
  }

  .profile-preview {
    width: 50%;
    padding-left: 30px;
    text-align: center;
  }

  .profile-preview img {
    max-width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .wrapper {
    display: inline;
  }

  .strong-text {
    font-weight: bold;
    font-family: var(--mulish);
    flex-shrink: 0;
  }

  .text {
    flex-grow: 1;
    font-family: var(--mulish);
  }

  .back-link {
    font-family: var(--mulish);
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    cursor: pointer;
    display: inline-block;
  }

  .feature-icons {
    width: 60px !important;
    height: 40px !important;
  }

  .modal-content {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }

  .close-icon-modal {
    align-self: flex-end;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    position: absolute;
    right: 15px;
  }

  .close-icon-modal:hover,
  .close-icon-modal:focus {
    color: black;
  }

  .profile-preview-modal {
    width: 100%;
    text-align: center;
  }

  .profile-preview-modal img {
    width: 900px;
    height: 450px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    @media (max-width: 1350px) {
      width: 800px;
    }

    @media (max-width: 1120px) {
      width: 700px;
    }

    @media (max-width: 880px) {
      width: 700px;
      height: auto;
    }

    @media (max-width: 800px) {
      width: 600px;
      height: auto;
    }

    @media (max-width: 710px) {
      width: inherit;
      height: auto;
    }
  }

  /* Modal Text Content */
  .modal-text-content {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }

  .modal-title {
    font-family: var(--fraunces);
    font-size: 32px !important;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    margin-bottom: 10px;
  }

  .modal-subtitle {
    font-family: var(--mulish);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #666;
  }

  .modal-form {
    display: flex;
    width: 100%;
  }

  .input-wrapper {
    width: 70%;
  }

  .modal-label {
    display: block;
    text-align: left;
    font-family: var(--fraunces);
    margin-bottom: 8px;
  }

  .modal-input {
    width: 100%;
    margin-bottom: 28px;
    font-family: var(--mulish);
    padding: 8px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    background-color: var(--beige-05);
  }

  .modal-button {
    height: 40px;
    margin-top: 30px;
    margin-left: 15px;
  }

  .cta-button:hover {
    background-color: #444;
  }

  .not-ready-screen {
    display: flex;
    flex-direction: row;

    .left-side {
      border-top-left-radius: 20px !important;
      border-bottom-left-radius: 20px !important;
      background-color: #f3f3f3;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;

      img {
        width: 100%;
        height: auto;
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 10px !important;
      }
    }

    .right-side {
      flex: 1;
      padding: 40px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-top-right-radius: 20px !important;
      border-bottom-right-radius: 20px !important;

      .not-ready-title {
        font-family: var(--fraunces);
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
      }

      .not-ready-text {
        font-family: var(--mulish);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }

      ol {
        padding-left: 1rem;
      }

      .cta-button {
        width: fit-content;
        height: 40px;
        margin-top: 10px;
      }
    }
  }

  .close-icon-button {
    font-size: 30px;
    position: absolute;
    right: 20px;
    cursor: pointer;
  }

  .error-text {
    color: red;
  }

  @media (max-width: 840px) {
    .screen {
      overflow: auto;
    }
    .close-icon-button {
      right: 8px;
    }
    .spash-container {
      flex-direction: column;
    }

    .content {
      width: 100%;
    }

    .profile-preview {
      width: 100%;
      padding-left: 0;
    }

    .modal-title {
      font-size: 24px !important;
      padding-left: 20px;
      padding-right: 20px;
    }

    .modal-subtitle {
      padding-left: 20px;
      padding-right: 20px;
    }

    .modal-form {
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column;
    }

    .input-wrapper {
      width: 100%;
    }

    .modal-button {
      margin-top: 10px;
      margin-left: 0;
    }

    .not-ready-screen {
      flex-direction: column;

      .left-side,
      .right-side {
        flex: none;
        width: 100%;
      }

      .left-side {
        border-top-right-radius: 20px !important;
        img {
          border-top-right-radius: 20px !important;
          width: 100%;
          height: auto;

          @media (max-width: 520px) {
            width: 100% !important;
          }

          @media (max-width: 400px) {
            width: 100% !important;

            height: auto;
          }
        }
      }
    }
  }
}

.not-ready-modal {
  width: 50% !important;
  max-width: 50% !important;
  --bs-modal-border-radius: 5.5rem !important;

  @media (max-width: 2200px) {
    width: 60% !important;
    max-width: 60% !important;
  }

  @media (max-width: 1500px) {
    width: 60% !important;
    max-width: 60% !important;
  }

  @media (max-width: 1330px) {
    width: 75% !important;
    max-width: 75% !important;
  }

  @media (max-width: 1090px) {
    width: 85% !important;
    max-width: 85% !important;
  }
  @media (max-width: 950px) {
    width: 95% !important;
    max-width: 95% !important;
  }
  @media (max-width: 840px) {
    width: 90% !important;
    max-width: 90% !important;
  }

  @media (max-width: 600px) {
    width: 90% !important;
    max-width: 90% !important;
  }
  @media (max-width: 560px) {
    width: 100% !important;
    max-width: 100% !important;
    transform: none !important;
    margin-left: 0px !important;
  }
}

.not-ready-modal-parent {
  display: none;
}

.not-ready-modal-small-width {
  display: none;
}

.modal-body-not-ready {
  padding: 0px;
}

.inner-modal {
  --bs-modal-border-radius: 5.5rem !important;

  z-index: 999999999;
}

.inner-modal-content {
  background-color: #fff;
  padding: 0;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: fit-content;
}

.fake-door-modal-component {
  max-width: 30% !important;

  @media (max-width: 2000px) {
    max-width: 45% !important;
  }

  @media (max-width: 1500px) {
    max-width: 60% !important;
  }

  @media (max-width: 1000px) {
    max-width: 75% !important;
  }
  @media (max-width: 850px) {
    max-width: 82% !important;
  }

  @media (max-width: 580px) {
    max-width: 95% !important;
  }
}
