@use './styles/mixins/media';

body {
  margin: 0;
  font-family: var(--mulish);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--purple-01);

  &.__overlay-open {
    overflow: hidden;
  }

  &.__blue-bg {
    background-color: var(--blue-reg);

    .__navigation-header {
      a.plain {
        color: white;
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --footer-height: 170px;
}

/*  APP  */
:root {
  --app-content-max-width: 1264px;
  --page-content-padding: var(--spacing-lg) var(--spacing-xxl) 0
    var(--spacing-xxl);

  --builder-content-padding: var(--spacing-lg) var(--spacing-xxl) 0
    var(--spacing-xxl);
  --builder-step-title-margin: var(--spacing-lg) 0;
  --builder-top-position: 44px;
  --builder-step-subtitle-margin: 0 0 var(--spacing-xl) 0;

  --main-layout-max-width: 1280px;

  @include media.max-width(sm) {
    --builder-content-padding: var(--spacing-base);
    --builder-step-title-margin: 0 0 var(--spacing-lg) 0;
    --builder-step-subtitle-margin: 0 0 var(--spacing-lg) 0;
    --builder-top-position: 32px;
  }

  @include media.max-width(fhd) {
    --app-content-max-width: calc(90%);
  }

  @include media.max-width(lg) {
    --app-content-max-width: calc(100% - 32px);
  }

  @include media.max-width(sm) {
    --app-content-max-width: 100%;
    --page-content-padding: var(--spacing-base);
  }
}

.toast.internal-toast {
  background-color: var(--green-dark);
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.view-pdf {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  .overlay {
    background-color: rgba(black, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
  }

  object {
    width: 80%;
    height: 90%;
    position: fixed;
    z-index: 1001;
    box-shadow: 4px 4px 8px 0px rgba(black, 0.3);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  .close {
    all: unset;
    cursor: pointer;
    font-size: 24px;
    padding: 3px 12px;
    color: white;
    border: 1px solid white;
    border-radius: 32px;
    position: absolute;
    top: 2%;
    right: 2%;
    opacity: 0.8;
    transition: 0.3s opacity;
    z-index: 1002;

    &:hover {
      opacity: 1;
    }
  }
}

.text-center {
  text-align: center;
}

.__route_page {
  .content {
    max-width: var(--app-content-max-width);
    margin: 0 auto var(--spacing-xl);
    background-color: #ffffff;
    border-radius: 32px;
    padding: var(--page-content-padding);

    @include media.max-width(sm) {
      border-radius: 0;
    }
  }
}

.processing-spinner {
  margin: 8px auto 16px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 4px solid var(--green-01);
  border-top-color: var(--green-06);
  border-right-color: var(--green-06);
  animation: processing-spinner 1.5s linear infinite;

  &.small {
    width: 20px;
    height: 20px;
    border-width: 2px;
  }
}

@keyframes processing-spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
