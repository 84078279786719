@use '../../styles/mixins/media';

.__purchase-modal-v2 {
  .modal-dialog {
    max-width: 1000px;
    margin: 0 auto;
  }

  .modal-body {
    padding: 0;
  }

  .modal-content {
    border-radius: var(--spacing-base);
    border: none;
    transform: scale(0.95);

    @include media.min-width(md) {
      transform: scale(0.8);
    }

    @include media.min-width(xl) {
      transform: scale(1);
    }

    @include media.max-width(sm) {
      min-height: 100dvh;
      border-radius: 0;
      transform: scale(1);
    }
  }
}
