@use '../../styles/mixins/media';

.trustpilot-container {
  background-color: var(--green-05);
  padding: var(--spacing-base);
  border-radius: 1rem;
  text-align: center;
  max-width: 400px;
  height: 125px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media.max-width(sm) {
    background-color: unset;
    height: fit-content;
    max-width: 200px;
    border: 1px solid var(--green-05);
    padding: 1px;
    margin-top: 15px;
    margin-left: 15px;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .trustpilot-heading {
    font-family: var(--mulish);
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;

    @include media.max-width(sm) {
      display: none;
    }
  }

  .trustpilot-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    background-color: white;
    padding: 5px;
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 2rem;
    text-align: center;
    text-decoration: none;
    color: #000;
    font-family: var(--mulish);
    font-size: 14px;
    font-weight: 700;
    text-wrap: nowrap;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    width: 100%;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);

      @include media.max-width(sm) {
        transform: scale(1.01);
      }
    }

    .trustpilot-star {
      width: 1.2rem;
      height: 1.2rem;
      color: var(--green-05);
    }
  }
}
