@use '../../styles/mixins/media';

.__mobile-header {
  width: 100%;

  .flex {
    display: flex;
  }

  .admin-label {
    color: var(--gray-07);
    font-family: var(--mulish);
    font-size: 20px;
    margin-bottom: 0px;
    margin-left: 30px;
    padding-bottom: 0px;
  }

  .mobile-header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .lines {
      width: 24px;
      height: 2px;
      background-color: var(--gray-09);
      border-radius: 2px;
    }
  }

  .drawer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: var(--spacing-lg) 0 var(--spacing-xl) 0;
    animation: 0.3s __mobile-menu-slide forwards;
    overflow: hidden;

    .drawer-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--spacing-xxl);
      padding: 0 var(--spacing-base);

      .title {
        font-size: 22px;
      }

      .close-snippet {
        all: unset;
        color: var(--gray-09);
        box-sizing: border-box;
        cursor: pointer;
        width: 24px;
        height: 24px;
        font-size: 22px;
        line-height: 22px;
        padding: 2px 4px;
        z-index: 3;
        transition: 0.3s border-color;
      }
    }

    .drawer-items {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      .top-items {
        flex: 1 1;
        width: 100%;

        .__create-document-button {
          margin-left: var(--spacing-base);
          margin-top: var(--spacing-sm);
        }
      }

      .bottom-items {
        width: 100%;
      }

      .section-item {
        padding: var(--spacing-sm) var(--spacing-base);
        color: var(--gray-09);
        font-family: var(--mulish);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }

      .mobile-nav-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: var(--spacing-md) var(--spacing-base);
        font-family: var(--mulish);
        color: var(--gray-09);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;

        &.active {
          background-color: var(--beige-05);
          font-weight: 700;
        }

        .avatar,
        svg {
          width: 20px;
          height: 20px;
          margin-right: var(--spacing-sm);
          border-radius: 10px;
        }
      }
    }

    .subscribe-btn {
      width: 100%;
      margin-top: var(--spacing-base);
    }
  }

  .new-badge {
    background-color: var(--green-01);
    color: var(--green-06);
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    padding: 4px 12px;
    margin-left: 10px;
  }
}
