.__modal {
  .modal-content {
    border-radius: 16px;
  }

  .modal-header {
    border-bottom: none;
    padding-top: 24px;
    padding-bottom: 0;
    padding-right: 24px;

    .btn-close {
      font-weight: bold;
      font-size: 14px;
      border: 1px solid var(--gray-8);
      border-radius: 100%;
    }
  }

  .modal-body {
    padding: 48px;

    @media screen and (max-width: 480px) {
      padding: 24px;
    }
  }
}
