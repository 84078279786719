@use '../../styles/mixins/media';

.__account-menu {
  @include media.max-width(md) {
    display: none;
  }

  .dropdown-toggle {
    background: var(--purple-07);
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #000000;
    color: #ffffff;
    line-height: 16px;

    &::after {
      display: none;
    }

    &:hover {
      color: #ffffff;
    }
  }

  .dropdown-menu {
    padding: var(--spacing-sm);
    border: none;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
    min-width: 200px;
    margin-top: var(--spacing-xs);

    .dropdown-item {
      font-family: var(--mulish);
      color: var(--gray-10);
      padding: var(--spacing-sm) var(--spacing-base);
      margin-bottom: var(--spacing-sm);
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
      border-radius: 8px;

      &:hover:not(.active) {
        background-color: var(--beige-02);
      }

      &.active {
        background-color: var(--beige-05);
        font-weight: 700;
      }
    }
  }
}
