@use '../../styles/mixins/media';

.__paywall-v2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  min-height: 650px;
  width: 100%;

  @include media.max-width(sm) {
    flex-direction: column;

    &.reversed {
      flex-direction: column-reverse;

      .left {
        background-color: #fff;
        padding-top: 0px;
      }
    }
  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 30px;
    font-family: var(--mulish);
    font-weight: 600;
  }

  .__highlight {
    .__highlight-color {
      background-color: var(--green-05);
    }
  }

  .label {
    font-size: var(--spacing-base);
    font-family: var(--mulish);
    font-weight: 400;
  }

  .stripe-label {
    font-size: var(--spacing-base);
    font-family: var(--fraunces);
    font-weight: 600;
    margin-bottom: 8px;
  }

  .left,
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-lg);
    box-sizing: border-box;

    @include media.max-width(sm) {
      padding: var(--spacing-base);
    }
  }

  .left-variant {
    background-color: var(--purple-01) !important;
  }

  .left {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: var(--purple-03);

    @include media.max-width(sm) {
      width: 100%;
    }

    @include media.min-width(md) {
      width: 50%;
      border-top-right-radius: 0;
      border-bottom-left-radius: 12px;
    }
  }

  .top-content {
    margin-bottom: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .updated-height {
    min-height: 768px;
  }

  .bottom-content {
    margin-top: auto;
  }

  .resume-icon {
    margin-bottom: 12px;
    margin-top: 20px;

    @include media.max-width(sm) {
      margin-top: var(--spacing-xxl);
      width: 80px;
    }
  }

  .profile-icon {
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 20px;
  }

  .item-variant {
    margin-bottom: 5px !important;
  }

  .items-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @include media.max-width(sm) {
      gap: 12px;
    }
  }

  .items-container .item:last-child {
    margin-bottom: 0;
  }

  .choose-plan-label {
    font-size: var(--spacing-base);
    line-height: var(--spacing-lg);
    font-family: var(--fraunces);
    font-weight: 600;

    @include media.max-width(sm) {
      margin-top: var(--spacing-xl);
    }
  }

  .choose-plan-label-variant {
    font-family: var(--mulish) !important;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }

  .hide {
    color: #fff;
    @include media.max-width(sm) {
      display: none;
    }
  }

  .strikethrough {
    text-decoration: line-through;
    margin-right: var(--spacing-xs);
  }

  .third-party-payments {
    margin-top: var(--spacing-lg);

    .separator {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid var(--gray-06);
      line-height: 0.1em;
      margin: 10px 0 20px;
      color: var(--gray-06);
      margin-top: var(--spacing-xl);

      span {
        background-color: #fff;
        padding: 0 10px;
      }
    }
  }

  .show {
    color: #000000;
  }

  .item-label {
    font-size: var(--spacing-base);
    font-family: var(--mulish);
    font-weight: 700;
  }

  .item-label-variant {
    font-size: var(--spacing-base);
    font-family: var(--mulish);
    font-weight: 400;
    margin-bottom: 0px;
  }

  .right {
    @include media.max-width(sm) {
      width: 100%;
    }

    @include media.min-width(md) {
      width: 50%;
    }
  }

  .plans {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    margin-top: var(--spacing-base);
    @include media.max-width(sm) {
      margin-bottom: var(--spacing-xxl);
      gap: var(--spacing-base);
    }
  }

  .plan-title {
    font-size: 20px;
    font-weight: 500;
    font-family: var(--mulish);
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .plan-subtitle {
    font-size: 14px;
    font-weight: 400;
    font-family: var(--mulish);
  }

  .smaller-plan {
    height: 105px !important;
    background-color: var(--beige-05);
    border: none !important;
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    padding: 24px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .white-plan {
    background-color: #fff;
    border: 2px solid var(--beige-05) !important;
  }

  .plan {
    height: 150px;
    cursor: pointer;
    border: 1px solid var(--gray-05);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-base);
    align-items: center;
  }
  .plan-multi-tier {
    height: 150px;
    cursor: pointer;
    border: 1px solid var(--gray-05);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-base);
    align-items: center;
  }

  .multi-tier-plan-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .full-width {
    width: 100%;
  }

  .tiers-selector {
    margin-top: 10px;
    background-color: var(--purple-03);
    width: 70%;
    flex: 1;
    border-radius: 20px;
    min-height: 40px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media.max-width(sm) {
      width: 95%;
    }

    .dynamic-plan-selector-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      flex: 1;
      width: fit-content;
      cursor: pointer;
    }
    .dynamic-plan-selector-badge-selected {
      background-color: var(--purple-09);
      color: #fff;
      border-radius: 20px;
      padding: 10px 0px;
      font-weight: bolder;

      p {
        font-weight: bolder;
      }
    }

    .mulish {
      font-family: var(--mulish);
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 0px;
    }
  }

  .plan-selected {
    border: 1px solid #000 !important;
  }

  .plan-price {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    margin-left: var(--spacing-lg);
  }

  .plan-price-variant {
    font-weight: bolder !important;
  }

  .plan-title-variant {
    font-size: 18px !important;
  }

  .plan-subtitle-variant {
    color: #46434d !important;
  }

  .left-margin {
    @include media.max-width(xs) {
      margin-left: var(--spacing-xs);
    }
  }

  .plan-tag-wrapper {
    background: var(--green-07);
    border-radius: var(--spacing-sm);
    color: #fff;
    font-size: 14px;
    line-height: var(--spacing-base);
    padding: var(--spacing-xs) var(--spacing-sm);
    position: absolute;
    bottom: 42px;
    right: -8px;
    margin-bottom: var(--spacing-lg);
    width: max-content;
  }

  .plan-selected-icon-wrapper-multi-tier {
    bottom: 100px !important;
  }
  .plan-selected-icon-wrapper {
    background: black;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 62px;
    right: -16px;
    margin: var(--spacing-sm);
  }

  .smaller-plan-icon-wrapper {
    bottom: 36px !important;
  }

  .plan-transparent-tag {
    font-family: var(--mulish);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--green-07);
    border: 1px solid var(--green-07);
    padding: 3px;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 6px;
    margin-left: 5px;

    @media (max-width: 760px) {
      display: none;
    }
    @include media.max-width(sm) {
      display: unset;
    }
  }

  .flex {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 2px;
  }

  .display-flex {
    display: flex;
  }

  .plan-period {
    color: var(--gray-08);
  }

  .prices {
    display: flex;
  }

  .price {
    font-size: 20px;
    font-family: var(--mulish);
    font-weight: 700;
  }

  .discounted-price {
    color: var(--green-08);
  }

  .bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 0px;

    @include media.max-width(xs) {
      flex-direction: column;
      align-items: center;
    }

    .grecaptcha-badge {
      position: absolute !important;
      left: 0 !important;

      @include media.max-width(sm) {
        left: var(--spacing-lg) !important;
        bottom: 74px !important;
      }

      @media (min-width: 768px) and (max-width: 992px) {
        left: 50% !important;
        bottom: 18px !important;
        transform: scale(0.8) !important;
      }

      @include media.min-width(lg) {
        left: calc(50% + var(--spacing-lg)) !important;
        bottom: var(--spacing-lg) !important;
      }
    }
  }

  .confirm-btn-wrapper,
  .continue-btn-wrapper {
    display: flex;
    justify-content: flex-end;

    @include media.max-width(sm) {
      width: 100%;
      justify-content: center;
      margin-top: var(--spacing-lg);
    }
  }

  .confirm-btn,
  .continue-btn {
    position: absolute;
    bottom: var(--spacing-lg);

    @include media.max-width(sm) {
      width: calc(100% - var(--spacing-xl));
      bottom: var(--spacing-base);
    }
  }

  .confirm-btn-wrapper {
    @include media.max-width(sm) {
      margin-top: 64px;
    }
  }

  .close-icon {
    float: right;
    position: absolute;
    right: var(--spacing-base);
    top: var(--spacing-base);
    width: var(--spacing-lg);
    height: var(--spacing-lg);
    cursor: pointer;

    @include media.min-width(sm) {
      right: var(--spacing-lg);
      top: var(--spacing-lg);
    }

    > svg {
      display: block;
      flex: 1;
      width: var(--spacing-lg);
      height: var(--spacing-lg);

      > ellipse {
        fill: transparent;
      }
    }
  }

  .__koi-alert.alert {
    background-color: var(--red-01);
    border: 1px solid var(--red-05);
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;

    > svg {
      height: var(--spacing-lg);
      max-width: var(--spacing-lg);
      margin-right: var(--spacing-sm);

      path {
        fill: var(--red-08);
      }
    }

    a {
      color: var(--gray-10);
    }

    @include media.min-width(md) {
      margin-top: var(--spacing-xxl);
    }
  }

  .sign-up {
    @include media.min-width(md) {
      margin-top: var(--spacing-xxl);
    }

    &.error {
      margin-top: var(--spacing-base);
    }

    .sso-btns {
      padding-bottom: var(--spacing-lg);
      border-bottom: 1px solid var(--gray-05);

      .__sso {
        gap: var(--spacing-base);
        align-items: center;

        .social-btn {
          width: 100%;
        }
      }

      .sso-error {
        padding: 12px;
        font-size: 12px;
        color: var(--error-color);
        text-align: center;
      }
    }

    .sign-up-form {
      margin-top: var(--spacing-base);

      .form-item {
        margin-bottom: var(--spacing-base);
        position: relative;

        .__truepath-input {
          position: relative;

          .form-control:valid {
            background-color: var(--beige-05);
          }
        }

        .eye-icon {
          position: absolute;
          top: 42px;
          right: var(--spacing-base);
          cursor: pointer;
        }
      }
    }
  }

  .selected-plan-details-variant {
    margin-top: 8px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    @include media.min-width(md) {
      margin-top: 16px !important;
    }
  }

  .selected-plan-details {
    background-color: var(--purple-01);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: var(--spacing-base);
    margin-top: var(--spacing-lg);

    svg {
      display: none;
    }

    .selected-plan-controls {
      display: flex;
      justify-content: space-between;
    }

    @include media.max-width(sm) {
      padding-bottom: var(--spacing-sm);
      margin-top: 0px;

      svg {
        display: block;
      }

      .hidden {
        display: none;
      }
    }
  }

  .include-icons {
    .checkmark-icon {
      display: block;

      path {
        stroke: var(--green-07);
      }
    }
  }

  .reversed {
    transform: scaleY(-1);
  }

  .selected-plan-label {
    font-size: var(--spacing-base);
    font-family: var(--mulish);
    margin-bottom: var(--spacing-xs);
  }

  .selected-plan-label-variant {
    font-size: 20px;
    font-family: var(--mulish);
    margin-bottom: var(--spacing-xs);
    font-weight: 500;
  }

  .main-header {
    font-size: var(--spacing-lg);
    font-family: var(--fraunces);
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 30px;
  }

  .main-title {
    font-size: var(--spacing-lg);
    font-family: var(--fraunces);
    font-weight: 600;
    margin-bottom: 0px;
  }

  .plan-feature-item {
    display: flex;
    gap: 10px;

    @include media.max-width(sm) {
      > p {
        margin-bottom: var(--spacing-sm);
      }
    }
  }

  .flex-item {
    display: flex;
    justify-content: space-between;
  }

  .green-label {
    color: var(--green-08);
  }

  .footer-info {
    .__testimonial-variant {
      border-left: var(--spacing-xs) solid var(--purple-05) !important;

      > svg {
        path {
          fill: var(--purple-05) !important;
        }
      }
    }
    .__testimonial {
      border-left: var(--spacing-xs) solid #f9f8fc;

      > svg {
        path {
          fill: #f9f8fc;
        }
      }
    }

    @include media.max-width(sm) {
      margin-top: var(--spacing-lg);
      margin-bottom: 35px;
    }

    @include media.min-width(md) {
      margin-top: 12px;
    }
  }

  .footer-info-variant-mobile {
    @include media.max-width(sm) {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  .disclaimer {
    color: var(--gray-09);
    margin-bottom: 0;
    font-size: 12px;
  }

  .arrow-icon-container {
    float: left;
    position: absolute;
    left: var(--spacing-base);
    top: var(--spacing-base);
    width: var(--spacing-lg);
    height: var(--spacing-lg);
    cursor: pointer;

    > svg {
      display: block;
      flex: 1;
      width: var(--spacing-lg);
      height: var(--spacing-lg);
    }
  }

  .billed-on-price {
    font-size: 14px;
    font-family: var(--mulish);
    font-weight: 700;
  }

  .card-user-name {
    margin-top: var(--spacing-lg);
  }

  .address-container,
  .card-user-name {
    .truepath-input-label {
      font-size: var(--spacing-base);
      font-family: var(--fraunces);
      font-weight: 600;
      margin-bottom: 8px;
    }

    .__truepath-input {
      .form-select:focus,
      .form-control:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid transparent;
      }
    }
  }

  .card-number {
    font-size: 18px;
    font-weight: 500;

    .input {
      padding: 12px;
      margin: var(--spacing-sm) 0;
      border-radius: var(--spacing-sm);
      background-color: var(--beige-05);

      .StripeElement {
        .__PrivateStripeElement {
          .ElementsApp .InputElement {
            font-size: 12px;
          }
        }
      }
    }
  }

  .address-container,
  .date-cvc-container {
    display: flex;
    gap: 16px;
    font-size: 18px;
    font-weight: 500;

    .expiry-date {
      .input {
        padding: 12px;
        border-radius: var(--spacing-sm);
        background-color: var(--beige-05);
      }
    }

    .updated-field {
      width: 50%;
    }

    .divider {
      flex-grow: 1;
      display: block;
      width: 50%;
    }

    .cvc {
      .input {
        padding: 12px;
        border-radius: var(--spacing-sm);
        background-color: var(--beige-05);
      }
    }
  }

  .powered-by-stripe {
    margin-top: var(--spacing-lg);
    margin-bottom: var(--spacing-sm);
    display: inline-block;
    width: fit-content;

    > svg {
      width: 120px;
      height: auto;
    }
  }

  .w-58 {
    width: 58% !important;
  }

  .apply-btn {
    border: none !important;
    color: var(--gray-10);

    @include media.max-width(sm) {
      width: 100%;
    }
  }

  .ml-20 {
    margin-right: 20px;
  }

  .promo-code-wrapper {
    display: flex;
    align-items: flex-end;

    @include media.max-width(sm) {
      flex-direction: column;
      margin-bottom: 60px;
    }
  }

  .invalid-code {
    color: var(--red-05);
    margin-top: 10px;
    margin-left: 5px;
  }

  .invalid-code-input {
    border: 1px solid var(--red-05) !important;
  }

  .promo-code-input {
    position: relative;

    @include media.max-width(sm) {
      width: 100%;
    }
  }

  .promo-code-icon {
    position: absolute;
    top: 35px;
    right: 15px;
    @media (max-width: 640px) {
      display: none;
    }
    @include media.max-width(sm) {
      display: block;
    }
  }

  .promo-code-icon-variant {
    top: 55px !important;
  }

  .main-subtitle {
    font-size: 12px;
    font-family: var(--mulish);
    font-weight: 400;
    color: var(--gray-08);
    margin-bottom: var(--spacing-lg);
  }

  .purchase-content {
    padding: var(--spacing-lg);

    @include media.max-width(sm) {
      padding: var(--spacing-base);
    }
  }

  .purchase-details {
    background-color: var(--purple-01);
    border-radius: 12px;
    padding: 20px;
    margin: var(--spacing-xl) auto;
    max-width: 360px;
    text-align: left;

    @include media.max-width(sm) {
      max-width: 100%;
      padding: var(--spacing-base);
      margin: 0;

      > p {
        margin-bottom: var(--spacing-sm);
      }
    }

    .purchase-details-subtitle {
      font-family: var(--fraunces);
      font-weight: 600;
      color: #000000;
      margin-bottom: var(--spacing-sm);

      @include media.max-width(sm) {
        font-size: var(--spacing-base);
        line-height: var(--spacing-lg);
      }
    }

    .purchase-details-label {
      margin-bottom: var(--spacing-lg);
    }
  }

  .purchase-subtitle {
    text-align: center;
    font-size: var(--spacing-base);
    font-family: var(--mulish);

    @include media.max-width(sm) {
      margin-bottom: var(--spacing-base);
    }

    .receipt-email {
      color: #000000;
      font-family: var(--mulish);
      font-size: var(--spacing-base);
      font-style: normal;
      font-weight: 700;
      line-height: var(--spacing-lg);
    }
  }

  .done-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: var(--spacing-lg);

    @include media.max-width(sm) {
      .__koi-button {
        margin-bottom: 0;
        position: absolute;
        bottom: var(--spacing-base);
        width: calc(100% - var(--spacing-xl));
        left: var(--spacing-base);
      }
    }
  }

  .purchase-icon-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: var(--spacing-lg);
    margin-top: var(--spacing-xxl);
  }

  .purchase-title {
    text-align: center;
  }

  .coupon-discount {
    margin-right: 10px;
    color: #716e78;
    text-decoration: line-through;
  }

  .free-trial-screen-header {
    background-color: var(--purple-09);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 3%;
    padding-bottom: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media.max-width(md) {
      padding-left: 10%;
      padding-right: 10%;
    }

    @include media.max-width(sm) {
      padding-left: 1%;
      padding-right: 1%;
      padding-top: 10%;
      padding-bottom: 10%;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }

  .free-trial-screen-title {
    font-family: var(--fraunces);
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
  }

  .trial-screen-copy {
    font-family: var(--mulish);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .trial-screen-disclaimer {
    font-family: var(--mulish);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #fff;
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    background-color: var(--purple-01);
  }

  .explanation-wrapper {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-top: -40px;
    width: 50%;
    background: #fff;
    display: flex;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 2%;
    gap: 20px;
    padding-top: 24px;

    @include media.max-width(md) {
      width: 60% !important;
    }

    @include media.max-width(sm) {
      width: 90% !important;
    }

    .first-item-mobile {
      margin-bottom: 32px;

      @include media.max-width(sm) {
        margin-bottom: 30px;
        margin-top: 15px;
      }

      @media (max-width: 390px) {
        margin-bottom: 5px;
      }
    }

    .second-item-mobile {
      @include media.max-width(sm) {
        margin-bottom: 5px;
      }

      @media (max-width: 390px) {
        margin-bottom: 10px;
      }
    }

    .timeline-icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -12px;
      @include media.max-width(md) {
        margin-top: 0px;
      }
    }

    .item {
      display: flex;
    }

    .item-title {
      font-family: var(--mulish);
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }

    .item-description {
      font-family: var(--mulish);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin-top: 10px;
    }
  }

  .pricing-plan {
    width: 100%;
    margin-top: 20px;
  }

  .plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .plan-header-title {
    font-family: var(--mulish);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 5px;

    @include media.max-width(sm) {
      margin-bottom: 8px;
    }
  }

  .price {
    font-family: var(--mulish);
    color: #1d1c28;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .free-trial-btn {
    display: inline-block;
    padding: 2px 10px;
    margin-top: 5px;
    font-size: 14px;
    color: var(--green-07);
    background-color: var(--purple-01);
    border: 1px solid var(--green-07);
    border-radius: 8px;
    cursor: pointer;
  }

  .total-due {
    display: flex;
    justify-content: space-between;
  }

  .price-today {
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }

  .billing-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .future-price {
    font-size: 16px;
    color: #333;
    margin-bottom: 0px;
  }

  .promo-code-label {
    margin-bottom: -8px;
    font-family: var(--mulish);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }

  .payment-divider {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .free-trial-terms ul {
    list-style: none;
    padding: 0;
  }

  .free-trial-terms li {
    position: relative;
    padding-left: 24px;
  }

  .free-trial-terms li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url('../../assets/icons/koi/check-icon.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .free-trial-button-wrapper {
    padding-top: 20px;
    @include media.max-width(sm) {
      display: flex;
      gap: 16px;
      flex-direction: column-reverse;
      bottom: 0px;
      background: #fff;
      padding: 10px 30px;
      position: sticky;
      width: 100%;
    }
  }

  .copy-container {
    @include media.max-width(sm) {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .mb-0 {
    margin-bottom: 0px !important;
  }

  .continue-btn-wrapper-variant {
    padding-top: 20px;
    @include media.max-width(sm) {
      display: flex;
      bottom: 0px;
      background: #fff;
      padding: 10px 30px;
      position: sticky;
      width: 100%;
      border: 1px solid white !important;
      z-index: 99999;
      opacity: 1;
    }

    .continue-btn-variant {
      width: 100%;
    }
  }

  .credit-card-info-container {
    margin-top: var(--spacing-lg);
    min-height: 116px;
  }

  .credit-card-info {
    display: flex;
    gap: var(--spacing-base);
  }

  .credit-card-info-company {
    text-transform: uppercase;
  }

  .credit-card-info-link {
    font-weight: 700;
    cursor: pointer;
  }
}
