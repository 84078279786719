@use '../../styles/mixins/media.scss';

.__koi-title {
  font-family: var(--fraunces);
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;

  @include media.max-width(sm) {
    font-size: 24px;
    line-height: 32px;
  }
}

.__highlight {
  position: relative;
  z-index: 0;
  display: inline-block;
  white-space: nowrap;

  &-color {
    position: absolute;
    z-index: -1;
    height: 40%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--purple-05);
    border-radius: 32px;
  }

  &-color-white {
    position: absolute;
    z-index: -1;
    height: 40%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 32px;
  }
}

.__highlight-section {
  position: relative;
  display: inline;
}

.__highlight-text {
  display: inline;
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/koi/highlight-icon.svg'),
    linear-gradient(180deg, var(--purple-05), var(--purple-05)),
    url('../../assets/icons/koi/highlight-icon.svg');
  background-position: left, center, right;
  background-position-y: bottom, bottom, bottom;
  background-size:
    auto 40%,
    90% 40%,
    auto 40%;
}
