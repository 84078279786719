/* GA STYLE GUIDE */
/* COLORS */
:root {
  --gray-01: #ffffff; // set here for reference but not to be used in the code, use "white" instead
  --gray-02: #fbfbfc;
  --gray-03: #f7f7f9;
  --gray-04: #eaeaea;
  --gray-05: #d6d5da;
  --gray-06: #bdbbc2;
  --gray-07: #97949e;
  --gray-08: #716e78;
  --gray-09: #46434d;
  --gray-10: #1d1c28;

  --red-01: #fcdedc;
  --red-02: #f9beba;
  --red-03: #f69e98;
  --red-04: #f37e76;
  --red-05: #f15e54;
  --red-06: #c04b43;
  --red-07: #a8413a;
  --red-08: #903832;
  --red-09: #602521;
  --red-10: #301210;
  --error-color: #dc3545;

  --green-01: #e3f8e9;
  --green-02: #bfdec8;
  --green-03: #9fcdad;
  --green-04: #7fbd92;
  --green-05: #60ad77;
  --green-06: #3a8551;
  --green-07: #437953;
  --green-08: #396747;
  --green-09: #26452f;
  --green-10: #132217;

  --purple-01: #f9f8fc;
  --purple-02: #f1edf5;
  --purple-03: #eae5f1;
  --purple-04: #e3dcec;
  --purple-05: #dcd4e8;
  --purple-06: #c1b3d6;
  --purple-07: #b3a2cd;
  --purple-08: #8f81a4;
  --purple-09: #6b617b;
  --purple-10: #474052;

  --beige-01: #fdfcfb;
  --beige-02: #fbfaf7;
  --beige-03: #f9f7f4;
  --beige-04: #f7f5f0;
  --beige-05: #f6f3ed;
  --beige-06: #eee8dc;
  --beige-07: #cec6b6;
  --beige-08: #b0a794;
  --beige-09: #847d6f;
  --beige-10: #58534a;
}

/* FONTS */
:root {
  --mulish: 'Mulish', sans-serif;
  --outfit: 'Outfit', sans-serif;
  --fraunces: 'Fraunces', serif;
}

:root {
  --spacing-base: 16px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 12px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 40px;
  --spacing-3xl: 48px;
  --spacing-4xl: 56px;
}
