@use '../styles/mixins/media';

.__public-layout {
  main {
    display: flex;
    justify-content: center;
    padding: var(--spacing-xl) var(--spacing-xxl);
    max-width: var(--main-layout-max-width);
    margin: 0 auto;

    @include media.max-width(sm) {
      padding: 0 var(--spacing-base) var(--spacing-base);
    }
  }
}
