.__koi-button,
.__koi-dropdown-button .dropdown-toggle {
  &-icon {
    &.btn {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &.btn {
    font-weight: 700;
    border-radius: 48px;
    font-family: var(--mulish);
    text-decoration: none;
    font-size: 16px;
    --bs-btn-padding-y: var(--spacing-sm);
    --bs-btn-padding-x: var(--spacing-xl);

    &.btn-sm {
      --bs-btn-padding-y: var(--spacing-xs);
    }
  }

  &.btn-primary {
    --bs-btn-border-color: transparent;
    --bs-btn-bg: var(--gray-10);
    --bs-btn-hover-bg: var(--gray-09);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-bg: var(--gray-10);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-bg: var(--gray-06);
    --bs-btn-disabled-border-color: transparent;
  }

  &.btn-secondary {
    --bs-btn-color: var(--gray-10);
    --bs-btn-bg: white;
    --bs-btn-border-color: var(--gray-10);
    --bs-btn-hover-color: var(--gray-09);
    --bs-btn-hover-bg: white;
    --bs-btn-hover-border-color: var(--gray-09);
    --bs-btn-active-color: var(--gray-10);
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: var(--gray-10);
    --bs-btn-disabled-color: var(--gray-06);
    --bs-btn-disabled-bg: white;
    --bs-btn-disabled-border-color: var(--gray-06);
    --bs-btn-disabled-opacity: 1;
  }

  &.btn-danger {
    --bs-btn-border-color: transparent;
    --bs-btn-bg: var(--red-05);
    --bs-btn-hover-bg: var(--red-04);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-bg: var(--red-05);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-bg: var(--red-03);
    --bs-btn-disabled-border-color: transparent;
  }

  &.btn-link {
    --bs-btn-active-color: var(--gray-09);
    --bs-link-color: var(--gray-10);
    --bs-link-hover-color: var(--gray-09);
  }

  &.__koi-icon-button {
    &.btn {
      border-radius: 8px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      flex-shrink: 0;

      &.btn-sm {
        padding: 0;
        width: 32px;
        height: 32px;
      }
    }
  }
}

.__koi-button-loading {
  margin-left: var(--spacing-sm);
}

.__koi-dropdown-button {
  .dropdown-toggle {
    display: flex;
    align-items: center;

    &::after {
      margin-left: var(--spacing-sm);
    }
  }

  .dropdown-menu {
    border: none;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
    padding: 0;
    margin-top: 2px;

    .dropdown-item {
      padding: var(--spacing-sm) var(--spacing-base);
      color: var(--gray-10);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      .menu-item-subtitle {
        color: var(--gray-09);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}
