$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;
$breakpoint-fhd: 1920px;
$breakpoint-2k: 2560px;
$breakpoint-4k: 3440px;

@mixin max-width($breakpoint) {
  @if ($breakpoint == xs) {
    @media (max-width: $breakpoint-xs) {
      @content;
    }
  } @else if ($breakpoint == sm) {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if ($breakpoint == md) {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  } @else if ($breakpoint == lg) {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if ($breakpoint == xl) {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
  } @else if ($breakpoint == xxl) {
    @media (max-width: $breakpoint-xxl) {
      @content;
    }
  } @else if ($breakpoint == fhd) {
    @media (max-width: $breakpoint-fhd) {
      @content;
    }
  } @else if ($breakpoint == 2k) {
    @media (max-width: $breakpoint-2k) {
      @content;
    }
  } @else if ($breakpoint == 4k) {
    @media (max-width: $breakpoint-4k) {
      @content;
    }
  } @else {
    @error "UNKNOWN MEDIA BREAKPOINT #{$breakpoint}";
  }
}

@mixin min-width($breakpoint) {
  @if ($breakpoint == xs) {
    @media (min-width: $breakpoint-xs) {
      @content;
    }
  } @else if ($breakpoint == sm) {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if ($breakpoint == md) {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if ($breakpoint == lg) {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else if ($breakpoint == xl) {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  } @else if ($breakpoint == xxl) {
    @media (min-width: $breakpoint-xxl) {
      @content;
    }
  } @else if ($breakpoint == fhd) {
    @media (min-width: $breakpoint-fhd) {
      @content;
    }
  } @else if ($breakpoint == 2k) {
    @media (min-width: $breakpoint-2k) {
      @content;
    }
  } @else if ($breakpoint == 4k) {
    @media (min-width: $breakpoint-4k) {
      @content;
    }
  } @else {
    @error "UNKNOWN MEDIA BREAKPOINT #{$breakpoint}";
  }
}

@mixin between-width($from, $to) {
  @if ($from == xs) {
    $from: $breakpoint-xs;
  } @else if ($from == sm) {
    $from: $breakpoint-sm;
  } @else if ($from == md) {
    $from: $breakpoint-md;
  } @else if ($from == lg) {
    $from: $breakpoint-lg;
  } @else if ($from == xl) {
    $from: $breakpoint-xl;
  } @else if ($from == xxl) {
    $from: $breakpoint-xxl;
  } @else if ($from == fhd) {
    $from: $breakpoint-fhd;
  } @else if ($from == 2k) {
    $from: $breakpoint-2k;
  } @else if ($from == 4k) {
    $from: $breakpoint-4k;
  }

  @if ($to == xs) {
    $to: $breakpoint-xs;
  } @else if ($to == sm) {
    $to: $breakpoint-sm;
  } @else if ($to == md) {
    $to: $breakpoint-md;
  } @else if ($to == lg) {
    $to: $breakpoint-lg;
  } @else if ($to == xl) {
    $to: $breakpoint-xl;
  } @else if ($to == xxl) {
    $to: $breakpoint-xxl;
  } @else if ($to == fhd) {
    $to: $breakpoint-fhd;
  } @else if ($to == 2k) {
    $to: $breakpoint-2k;
  } @else if ($to == 4k) {
    $to: $breakpoint-4k;
  }

  @media (min-width: $from) and (max-width: $to - 1) {
    @content;
  }
}
