@use '../../styles/mixins/media';

.__sso {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  @include media.max-width(sm) {
    align-items: center;
  }

  .social-btn {
    max-width: var(--max-content-width);
    padding: var(--spacing-sm) var(--spacing-xl);
    border: 1px solid var(--gray-10);
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    min-width: 256px;
    width: 100%;

    @include media.min-width(lg) {
      width: var(--max-content-width);
    }
  }
}
