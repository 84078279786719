@use '../../styles/mixins/media';

.__truepath-input {
  --tp-input-font-size: 16px;
  --tp-input-line-height: 24px;
  --tp-input-padding: var(--spacing-sm) var(--spacing-base);
  --tp-checkbox-size: 24px;

  .form-control,
  .form-select {
    font-family: var(--mulish);
    font-size: var(--tp-input-font-size);
    line-height: var(--tp-input-line-height);
    padding: var(--tp-input-padding);
    background-color: var(--beige-05);
    border-radius: 8px;
    border-color: transparent;
    font-style: normal;
    font-weight: 400;

    &::placeholder {
      color: var(--gray-07);
    }

    &:focus {
      background-color: var(--beige-05);
    }

    &.is-valid,
    &:valid {
      border-color: transparent !important;
    }
  }

  .form-control {
    padding-right: var(--spacing-base) !important;
    background-image: none !important;

    &.is-invalid,
    &:invalid {
      background-image: none !important;
    }

    &.is-invalid {
      border-color: var(--error-color) !important;
    }
  }

  .form-select {
    padding-right: var(--spacing-lg) !important;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e') !important;
    background-position: right 0.4rem center !important;
  }

  .form-error {
    border-color: var(--error-color) !important;
  }

  .form-check {
    font-family: var(--mulish);
    font-size: var(--tp-input-font-size);
    line-height: var(--tp-input-line-height);
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;

    .form-check-input {
      width: var(--tp-checkbox-size);
      min-width: var(--tp-checkbox-size);
      height: var(--tp-checkbox-size);
      min-height: var(--tp-checkbox-size);
      background-color: var(--beige-05);
      border: none;
      border-radius: 6px;
      margin-right: var(--spacing-sm);

      &:checked {
        background-color: var(--gray-10) !important;
      }

      &.is-valid,
      &:valid {
        border: none;

        ~ .form-check-label {
          color: black;
        }
      }

      &.is-invalid,
      &:invalid {
        background-image: none !important;
      }
    }

    .form-check-label {
      color: black;
    }
  }

  .invalid-feedback {
    margin-top: 0;
    font-size: 10px;
    color: var(--red-05);
    font-family: var(--mulish);

    // alert (!) icon
    &:before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 8C15 11.866 11.866 15 8 15C4.134 15 1 11.866 1 8C1 4.134 4.134 1 8 1C11.866 1 15 4.134 15 8ZM8.5 8.15088C8.5 8.42703 8.27615 8.65088 8 8.65088C7.72385 8.65088 7.5 8.42703 7.5 8.15088L7.5 5.70497C7.5 5.42882 7.72385 5.20497 8 5.20497C8.27615 5.20497 8.5 5.42882 8.5 5.70497L8.5 8.15088ZM8 9.79498C7.72385 9.79498 7.5 10.0188 7.5 10.295C7.5 10.5711 7.72385 10.795 8 10.795C8.27615 10.795 8.5 10.5711 8.5 10.295C8.5 10.0188 8.27615 9.79498 8 9.79498Z' fill='%23F15E54'/%3E %3C/svg%3E ");
      vertical-align: sub;
      margin-inline-end: 4px;
    }
  }

  .rbt-highlight-text::before,
  .rbt-highlight-text::after {
    content: '"';
  }

  .rbt-highlight-text {
    background: none !important;
  }

  .rbt-input-hint {
    font-family: var(--mulish) !important;
    font-size: var(--tp-input-font-size) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: var(--tp-input-line-height) !important;
    padding: var(--tp-input-padding) !important;
  }
}

.truepath-input-label {
  --tp-input-label-font-size: 14px;
  --tp-input-label-line-height: 16px;

  color: var(--gray-10);
  font-family: var(--fraunces);
  font-size: var(--tp-input-label-font-size);
  line-height: var(--tp-input-label-line-height);
  font-weight: 400;
  font-style: normal;
  margin-bottom: var(--spacing-sm);

  .required-mark {
    color: var(--red-05);
    margin-left: var(--spacing-xs);
  }
}

.koi-autocomplete {
  .wrapper {
    position: relative;

    svg {
      position: absolute;
      transform: translate(50%, 75%);
      z-index: 4;
    }
  }

  .rbt-input-main,
  .rbt-input-hint {
    padding-left: 32px !important;
  }

  .autocomplete-input {
    background-color: var(--beige-05);
    border-radius: 8px;
  }

  .form-control:focus {
    border: 1px solid var(--beige-08) !important;
    box-shadow: none;
  }

  .form-control:disabled {
    background-color: var(--gray-03) !important;
  }

  .__truepath-input {
    .dropdown-item {
      align-items: center;
      color: var(--gray-10);
    }

    .dropdown-item {
      &.active,
      &:active {
        background-color: var(--gray-03);
      }
    }

    .autocomplete-input {
      z-index: 3;
    }
  }

  .autocomplete-option {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      z-index: 3;
    }

    .autocomplete-txt {
      margin-left: 24px;
    }
  }
}
