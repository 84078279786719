@use '../../styles/mixins/media';

.__navigation-header {
  --nav-header-padding: var(--spacing-xl) 0;
  --nav-header-title-font-size: 32px;
  --nav-header-title-line-height: 40px;

  @include media.max-width(sm) {
    --nav-header-padding: var(--spacing-lg) var(--spacing-base);
    --nav-header-title-font-size: 22px;
    --nav-header-title-line-height: 32px;
  }

  padding: var(--nav-header-padding);
  max-width: var(--app-content-max-width);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: sticky;
  z-index: 100;

  .__koi-button.btn-link {
    font-weight: normal;
    line-height: 22px;
  }
}

// avoid scrolling when menu is open:
@media only screen and (max-width: 720px) {
  .__body-fixed-mobile {
    overflow: hidden;
    max-height: 100dvh;

    #root {
      overflow: hidden;
      height: 100dvh;
    }

    .__wizard-footer {
      z-index: 0 !important;
    }
  }
}

@keyframes __mobile-menu-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes __mobile-menu-slide {
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(0);
  }
}
