@use '../../styles/mixins/media';

.__testimonial {
  margin-top: auto;
  font-family: var(--mulish);
  border-left: var(--spacing-xs) solid var(--purple-05);
  padding: 12px var(--spacing-lg);
  font-weight: 400;

  @include media.max-width(sm) {
    padding: var(--spacing-sm) var(--spacing-base);
  }

  .quote {
    font-size: var(--spacing-base);
    line-height: var(--spacing-lg);
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-base);
  }

  .user {
    display: flex;

    .name {
      font-family: var(--fraunces);
      font-weight: 600;
      margin-bottom: var(--spacing-xs);
      margin-left: 12px;
    }

    .details {
      font-size: 14px;
      line-height: var(--spacing-base);
      margin-left: 12px;
    }
  }
}
